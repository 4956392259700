import Cmp from './NewsItem.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query currentNewsQuery($wordpress_id: Int!) {
    wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      slug
      id
      featured_media {
        localFile {
          childImageSharp {
            fixed(height: 500, width: 920 quality: 92) {
              src
            }
          }
        }
      }
      author {
        id
        name
        avatar_urls {
          wordpress_24
          wordpress_48
          wordpress_96
        }
        slug
      }
      wordpress_id
      type
      date
      acf {
        meta_title
        meta_description
        meta_image {
          localFile {
            url
          }
        }
        keywords
      }
    }
  }
`

export default Cmp
