import React, { useContext } from 'react'
import { format } from 'date-fns'
import { is } from 'date-fns/locale'
import { get } from 'lodash'
import styles from './NewsItem.module.scss'
import Facebook from '../../images/shareIcons/facebook.png'
import Envelope from '../../images/shareIcons/envelope.png'
import Linkdin from '../../images/shareIcons/linkdin.png'
import { LanguageContext } from '../../context'
import { TRANSLATIONS } from '../../misc/translations.js'
import classnames from 'classnames'

export default ({
  title,
  date,
  featured_media,
  content,
  author,
  slug
}) => {
  const { currentLang: language } = useContext(LanguageContext)
  const imageUrl = get(featured_media, 'localFile.childImageSharp.fixed.src', null)
  const formattedDate = format(new Date(date), 'd. MMMM yyyy', { locale: is })
  const name = author && author.name
  const avatarUrls = author && author.avatar_urls
  const shareUrl = language === 'is' ? `https://www.ljosleidarinn.is/frettir/${slug}` : `https://www.ljosleidarinn.is/en/news/${slug}`
  const message = 'mailto:?subject=' + title + '&body=' + shareUrl

  return (
    <section>
      <div className='container'>
        {imageUrl &&
          <div className='row'>
            <div className='col-md-10'>
              <div className={styles.imageContainer}>
                <div className={styles.image} style={{ backgroundImage: `url(${imageUrl})` }} />
              </div>
            </div>
          </div>
        }
        <div className='row pt-4 pt-md-5'>
          <div className='col-6 col-lg-2 d-flex flex-lg-column align-items-lg-end pr-lg-5'>
            <a href={message} title='Deila með tölvupósti'>
              <img src={Envelope} className='mb-lg-3 social-icon' />
            </a>
            <img src={Facebook} className='mb-lg-3 social-icon' onClick={() => window.open('https://www.facebook.com/sharer/sharer?u=' + shareUrl,
              'facebook-share-dialog',
              'width=800,height=450,left=400'
            )} />
            <img src={Linkdin} className='mb-4 mb-lg-3 social-icon' onClick={() => window.open('https://www.linkedin.com/cws/share?url=' + shareUrl,
              'facebook-share-dialog',
              'width=800,height=450,left=400'
            )} />
          </div>

          <div className='col-md-10 col-lg-8'>
            <article className='news-content'>
              <h2>{title}</h2>
              <p className='mb-1'>{formattedDate}</p>
            </article>
            <article className='news-content'
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <p className='pt-3 mb-0'>
              {TRANSLATIONS[language].NEWS_FOOTNOTE}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
